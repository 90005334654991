import { useSelector } from 'react-redux';
import * as Service from 'services/tenant';

export const useTenantActions = () => {
  const { token, tenant_id } = useSelector((state) => state.user_data);

  const readById = async (id, query_string = '') => {
    return await Service.readById(id, {
      query_string: `${query_string}&tenant_id=${tenant_id}`,
      token
    });
  };

  const generateAPIKey = async (id) => {
    return await Service.generateAPIKey(id, {
      data: { tenant_id },
      token
    });
  };

  return { readById, generateAPIKey };
};
