import { useSelector } from 'react-redux';
import * as Service from 'services/role';

export const useRoleActions = () => {
  const { token, tenant_id } = useSelector((state) => state.user_data);

  const create = async (data) => {
    return await Service.create({
      data: { ...data, tenant_id },
      token
    });
  };

  const read = async (query_string = '') => {
    return await Service.read({
      query_string: `${query_string}&tenant_id=${tenant_id}`,
      token
    });
  };

  const update = async (id, data) => {
    return await Service.updateById(id, {
      data: { ...data, tenant_id },
      token
    });
  };

  const deleteById = async (id) => {
    return await Service.deleteById(id, {
      token,
      data: { tenant_id }
    });
  };

  return { create, read, update, deleteById };
};
