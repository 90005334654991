/** */
import {
  ADD_MANY_ROLES_TO_STORE,
  ADD_ONE_ROLE_TO_STORE,
  ADD_SEARCH_RESULTS_TO_STORE,
  LOAD_ROLES,
  REM_ROLE
} from '../actions/role';

export default (state = {}, action) => {
  const { type, payload } = action;
  const current_state = { ...state };
  switch (type) {
    case ADD_MANY_ROLES_TO_STORE:
      return {
        ...state,
        ...payload.reduce(
          (roles, role) => ({
            ...roles,
            [role.id]: role
          }),
          {}
        )
      };
    case ADD_SEARCH_RESULTS_TO_STORE:
      return {
        ...payload.reduce(
          (roles, role) => ({
            ...roles,
            [role.id]: role
          }),
          {}
        )
      };
    case ADD_ONE_ROLE_TO_STORE:
      return {
        ...state,
        [payload.id]: payload
      };
    case LOAD_ROLES:
      return {
        ...payload.reduce(
          (roles, role) => ({
            ...roles,
            [role.id]: role
          }),
          {}
        )
      };
    case REM_ROLE:
      delete current_state[payload];
      return { ...current_state };
    default:
      return state;
  }
};
