const ensureUniformEnvironmentCriteria = (criteria, environments) => {
  const environment_codes = Object.keys(environments);
  const criteria_by_env = {};
  environment_codes.forEach((code) => {
    criteria_by_env[code] = {
      code,
      criteria: Object.values(criteria).map((criterion) => ({
        property: criterion.property,
        values: []
      }))
    };
  });

  return criteria_by_env;
};

const mapCriteriaByProperty = (criteria = []) => {
  return criteria.reduce(
    (s, criterion) => ({
      ...s,
      [criterion.property]: criterion
    }),
    {}
  );
};

const applyExistingCriteriaValues = (criteria_by_env, environments) => {
  const existing_criteria_by_env = Object.values(environments).reduce(
    (sac, env) => ({
      ...sac,
      [env.code]: mapCriteriaByProperty(env.criteria)
    }),
    {}
  );

  return Object.values(criteria_by_env).map((environment) => {
    const { code, criteria } = environment;
    return {
      ...environment,
      criteria: criteria.map((criterion) => {
        const existing_criterion = existing_criteria_by_env[code][criterion.property];
        return {
          ...criterion,
          values: existing_criterion ? existing_criterion.values : []
        };
      })
    };
  });
};

const applyNewValuesCriteriaValues = (
  criteria_with_existing_values,
  incoming_criteria,
  current_env
) => {
  const incoming_criteria_by_property = Object.values(incoming_criteria).reduce(
    (sac, ic) => ({
      ...sac,
      [ic.property]: ic
    }),
    {}
  );

  // for each criterion in each `criterion_with_existing_values`,
  // the incoming value takes precedence
  return criteria_with_existing_values.map((pc) => {
    if (current_env !== pc.code) return pc;
    return {
      ...pc,
      criteria: pc.criteria.map((criterion) => {
        const incoming = incoming_criteria_by_property[criterion.property];
        return {
          ...criterion,
          values: Array.isArray(incoming.values)
            ? incoming.values
            : incoming.values.split(',').map((v) => v.trim())
        };
      })
    };
  });
};

export const processFlagConfig = (criteria, environments, environment) => {
  const criteria_by_env = ensureUniformEnvironmentCriteria(criteria, environments);
  const with_existing_values = applyExistingCriteriaValues(criteria_by_env, environments);
  const prepared_criteria = applyNewValuesCriteriaValues(
    with_existing_values,
    criteria,
    environment.code
  );

  const criteria_to_save = Object.values(criteria).map((criterion) => ({
    property: criterion.property,
    condition: criterion.condition.value,
    evaluation: criterion.evaluation.value
  }));

  return {
    environments: prepared_criteria,
    criteria: criteria_to_save
  };
};

export const formatFlagForDisplay = (payload, environments) => {
  const { environments: db_envs, criteria: db_criteria } = payload;

  const property_values = {};
  const envs = {};
  db_envs.forEach(({ code, criteria }) => {
    envs[code] = { code, name: environments[code].name, criteria };
    criteria.forEach(({ property, values }) => (property_values[property] = values));
  });

  const criteria = db_criteria.reduce(
    (sac, { property, condition, evaluation }) => ({
      ...sac,
      [property]: {
        property,
        condition: { value: condition },
        evaluation: { value: evaluation }
      }
    }),
    {}
  );

  return { criteria, environments: envs };
};
