import React from 'react';
import { Switch, Route } from 'react-router-dom';

import { CreateFlag } from './create';
import List from './list';

const FlagsModule = () => {
  return (
    <Switch>
      <Route path="/flags/:id" component={(route) => <CreateFlag id={route.match.params.id} />} />
      <Route path="/flags" component={List} />
    </Switch>
  );
};

export default FlagsModule;
