/** */
export const READ_MANY_FLAGS = 'read multiple flags.';
export const READ_ONE_FLAG = 'read one flag.';

export const ADD_ONE_FLAG_TO_STORE = 'add one flag to store.';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add flag search results to store.';
export const ADD_MANY_FLAGS_TO_STORE = 'add multiple flags to store.';
export const LOAD_FLAGS_TO_STORE = 'load up flags to store.';
export const REM_ONE_FLAG_FROM_STORE = 'remove a flag from the store.';

/** */
export const readManyFlags = (payload) => ({
  type: READ_MANY_FLAGS,
  payload
});

export const readOneFlag = (payload) => ({
  type: READ_ONE_FLAG,
  payload
});

export const addManyFlagsToStore = (payload) => ({
  type: ADD_MANY_FLAGS_TO_STORE,
  payload
});

export const addOneFlagToStore = (payload) => ({
  type: ADD_ONE_FLAG_TO_STORE,
  payload
});

export const addFlagSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadFlagsToStore = (payload) => ({
  type: LOAD_FLAGS_TO_STORE,
  payload
});

export const removeOneFlagFromStore = (payload) => ({
  type: REM_ONE_FLAG_FROM_STORE,
  payload
});
