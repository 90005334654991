/** */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import * as GuestService from 'services/guest';
import { addDataToStore } from 'store/actions/user-data';
import { formIsEmpty } from 'utilities/form';
import { Alert } from 'app/shared/form-alert';
import { Button } from 'app/shared/button';
import { IconInput, SimpleInput } from 'app/shared/input';
import styles from '../guest.module.css';

const LoginForm = () => {
  const [alert, setAlert] = useState({});
  const [form_data, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const [show_password, setShowPassword] = useState(false);

  const dispatch = useDispatch();
  const history = useHistory();

  const submitForm = () => {
    if (formIsEmpty(form_data)) {
      setAlert({ message: 'Please fill all fields.' });
      return;
    }

    if (!form_data.email_address) {
      setAlert({ message: 'Invalid Email Address.' });
      return;
    }

    if (form_data.password.length < 8) {
      setAlert({ message: 'Passwords must contain 8 or more characters.' });
      return;
    }

    setLoading(true);
    GuestService.login({ data: form_data })
      .then((response) => {
        const { error, payload } = response;
        if (error) {
          setAlert({ message: error });
          return;
        }

        const active_tenant = payload.tenants
          ? payload.tenants.find((tenant) => tenant.status === 'accepted')
          : null;
        dispatch(
          addDataToStore({
            ...payload,
            tenant_id: active_tenant?.id || 0
          })
        );
      })
      .finally(() => setLoading(false));
  };

  const toggleShowPassword = () => {
    setShowPassword(!show_password);
  };

  return (
    <div>
      <Alert {...alert} />
      <SimpleInput
        placeholder="Email address"
        onInput={(value) => setFormData({ ...form_data, email_address: value })}
        value={form_data.email_address}
      />
      <IconInput
        placeholder="Password"
        icon_name={show_password ? 'hide' : 'show'}
        type={show_password ? 'text' : 'password'}
        icon_position="right"
        onInput={(value) => setFormData({ ...form_data, password: value })}
        onIconClick={toggleShowPassword}
        value={form_data.password}
      />
      <div className={styles.formText} onClick={() => history.push('/password/recovery')}>
        Recover password?
      </div>
      <Button
        className={styles.formBtn}
        onClick={() => submitForm()}
        text="Log In"
        loading={loading}
      />
      <Button
        className={styles.formBtn}
        onClick={() => history.push('/register')}
        text="Create Account"
        type="secondary"
      />
    </div>
  );
};

export default LoginForm;
