/** */
export const READ_MANY_ENVIRONMENTS = 'read multiple ENVIRONMENTs.';
export const READ_ONE_ENVIRONMENT = 'read one ENVIRONMENT.';

export const ADD_MANY_ENVIRONMENTS_TO_STORE = 'add multiple ENVIRONMENTs to atore.';
export const ADD_ONE_ENVIRONMENT_TO_STORE = 'add one ENVIRONMENT to store.';
export const ADD_SEARCH_RESULTS = 'add search results ENVIRONMENT to store.';
export const LOAD_ENVIRONMENTS_TO_STORE = 'load up ENVIRONMENTs to store.';
export const REM_ENVIRONMENT_FROM_STORE = 'remove on ENVIRONMENT from the store.';
export const REM_MANY_ENVIRONMENTS_FROM_STORE = 'remove multiple ENVIRONMENTS from the store.';

/** */
export const readManyEnvironments = (payload) => ({
  type: READ_MANY_ENVIRONMENTS,
  payload
});

export const readOneEnvironment = (payload) => ({
  type: READ_ONE_ENVIRONMENT,
  payload
});

export const addManyEnvironmentsToStore = (payload) => ({
  type: ADD_MANY_ENVIRONMENTS_TO_STORE,
  payload
});

export const addOneEnvironmentToStore = (payload) => ({
  type: ADD_ONE_ENVIRONMENT_TO_STORE,
  payload
});

export const addEnvironmentSearchResults = (payload) => ({
  type: ADD_SEARCH_RESULTS,
  payload
});

export const loadEnvironmentsToStore = (payload) => ({
  type: LOAD_ENVIRONMENTS_TO_STORE,
  payload
});

export const removeEnvironmentFromStore = (payload) => ({
  type: REM_ENVIRONMENT_FROM_STORE,
  payload
});

export const removeManyEnvironmentFromStore = (payload) => ({
  type: READ_MANY_ENVIRONMENTS,
  payload
});
