import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { formIsEmpty } from 'utilities/form';

import * as GuestService from 'services/guest';
import { SimpleInput } from 'app/shared/input';
import { Button } from 'app/shared/button';
import { Alert } from 'app/shared/form-alert';
import styles from '../guest.module.css';

const PasswordRecovery = () => {
  const [alert, setAlert] = useState({});
  const [form_data, setFormData] = useState({});
  const [is_loading, setIsLoading] = useState(false);
  const history = useHistory();
  const submitForm = () => {
    if (formIsEmpty(form_data)) {
      setAlert({ message: 'Please fill all fields.' });
      return;
    }

    if (!form_data.email) {
      setAlert({ message: 'Invalid Email Address.' });
      return;
    }

    setIsLoading(true);
    GuestService.recoverPassword({ data: form_data })
      .then((response) => {
        const { error, payload } = response;

        if (error) {
          setAlert({ message: error });
          return;
        }

        setAlert({
          message: payload,
          type: 'success'
        });
      })
      .finally(() => setIsLoading(false));
  };

  return (
    <div>
      <Alert {...alert} />
      <SimpleInput
        type="email"
        placeholder="Email Address"
        onInput={(value) => setFormData({ ...form_data, email: value })}
        value={form_data.email}
      />
      <Button
        className={styles.formBtn}
        onClick={() => submitForm()}
        text="Recover Password"
        loading={is_loading}
      />
      <div className={styles.formText} onClick={() => history.push('/login')}>
        Back to <b>Login</b>
      </div>
    </div>
  );
};

export default PasswordRecovery;
