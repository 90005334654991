/** */
import { combineReducers } from 'redux';

import roles from './reducers/role';
import flags from './reducers/flag';
import users from './reducers/user';
import header from './reducers/header';
import metadata from './reducers/metadata';
import plans from './reducers/plan';
import processes from './reducers/process';
import environments from './reducers/environments';
import user_data from './reducers/user-data';
import { LOGOUT } from './actions/user-data';

const appReducer = combineReducers({
  roles,
  flags,
  users,
  header,
  metadata,
  plans,
  processes,
  environments,
  user_data
});

const rootReducer = (state, action) => {
  // when a logout action is dispatched it will reset redux state
  if (action.type === LOGOUT) {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
