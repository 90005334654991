/** */
import { toast } from 'react-toastify';
import React, { useState, useEffect } from 'react';
import fileSaver from 'js-file-download';
import { useSelector } from 'react-redux';
import * as ContactService from 'services/user';
import * as TemplateService from 'services/template';
import { Button } from 'app/shared/button';
import { MultiSelect } from 'app/shared/select';

const ImportContact = (props) => {
  const { closeModal, mailing_list, onDataRequest } = props;
  const [file, setFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [mailing_lists, setMailingLists] = useState([]);
  const [selected_list, setSelectedList] = useState([]);

  const user_data = useSelector((state) => state.user_data);
  const stored_users = useSelector((state) => state.users);
  const { token } = user_data;
  const tenant_id = user_data.id;

  useEffect(() => {
    setMailingLists(() => Object.values(stored_users));
  }, [stored_users]);

  const downloadTemplate = async () => {
    const response = await TemplateService.download('contact', { token });
    fileSaver(response, 'contacts.csv');
  };

  const resetImport = () => {
    setSelectedList([]);
    setFile(null);
  };

  const submit = () => {
    if (!file) {
      toast.error('no file selected');
      return;
    }

    if (!file.type.includes('csv') && !file.type.includes('vnd')) {
      toast.error('invalid file type: must be csv or xlsx');
      return;
    }

    const request_data = new FormData();
    request_data.append('contacts', file);
    request_data.append('list_id', mailing_list ? mailing_list.id : selected_list.value);
    request_data.append('tenant_id', tenant_id);

    setLoading(true);
    ContactService.upload({
      data: request_data,
      token,
      headers: {
        'Content-Type': 'application/form-data'
      }
    }).then((data) => {
      console.log(data);
      setLoading(false);
      if (!data || data.error) {
        toast.error(data.error);
        return;
      }

      toast.success('Contacts uploaded successfully.');
      onDataRequest();
      closeModal();
      resetImport();
    });
  };

  return (
    <div>
      <div className="custom-file">
        <input
          type="file"
          className="custom-file-input"
          id="contact_file"
          onChange={(e) => setFile(e.target.files[0])}
        />
        <label className="custom-file-label" htmlFor="contact_file">
          {file ? file.name : 'Select file'}
        </label>
      </div>
      <div className="mt-3">
        {mailing_list ? (
          <select className="custom-select mb-3" disabled>
            <option>{mailing_list.name}</option>
          </select>
        ) : (
          <MultiSelect
            options={mailing_lists.map((list) => ({
              label: list.name,
              value: list.id
            }))}
            onChange={setSelectedList}
            value={selected_list}
            placeholder="Select audience"
            isMulti={false}
          />
        )}
      </div>
      <div className="grid cols-2">
        <Button
          onClick={() => downloadTemplate()}
          className="w-100"
          text="Download Template"
          type="secondary"
        />
        <Button onClick={submit} className="w-100" text="Import" loading={loading} />
      </div>
    </div>
  );
};

export default ImportContact;
