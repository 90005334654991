import { urls } from './_url';
import { apiGet, apiPut } from './_http';

const base_url = `${urls.server}/tenants`;

export const readById = async (id, options = {}) => {
  return await apiGet(`${base_url}/${id}`, { ...options });
};

export const generateAPIKey = async (id, options = {}) => {
  return await apiPut(`${base_url}/${id}/key`, { ...options });
};

export const updateProfileById = async (id, options = {}) => {
  return await apiPut(`${base_url}/${id}`, { ...options });
};

export const updatePasswordById = async (id, options) => {
  return await apiPut(`${base_url}/password/${id}`, { ...options });
};
