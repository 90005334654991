import { useSelector } from 'react-redux';
import * as Service from 'services/flag';

export const useFlagActions = () => {
  const { token, tenant_id } = useSelector((state) => state.user_data);

  const create = async (data) => {
    return await Service.create({
      data: { ...data, tenant_id },
      token
    });
  };

  const read = async (query_string = '') => {
    return await Service.read({
      query_string: `${query_string}&tenant_id=${tenant_id}`,
      token
    });
  };

  const readById = async (id, query_string = '') => {
    return await Service.readById(id, {
      query_string: `${query_string}&tenant_id=${tenant_id}`,
      token
    });
  };

  const search = async (keys, keywords, query_string = '') => {
    return await Service.search(keys, keywords, {
      token,
      query_string
    });
  };

  const update = async (id, data) => {
    return await Service.updateById(id, {
      data: { ...data, tenant_id },
      token
    });
  };

  return { create, read, readById, update, search };
};
