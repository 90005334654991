import React, { useEffect, useState } from 'react';
import styles from '../api.module.css';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { useRoleActions } from 'hooks/roles';
import { addOneRoleToStore } from 'store/actions/role';

const ENV_VALUES = [
  {
    label: 'Read',
    value: 'read'
  },
  {
    label: 'Write',
    value: 'write'
  },
  {
    label: 'Full Access',
    value: 'full'
  }
];

export const RolesForm = ({ role }) => {
  const dispatch = useDispatch();
  const { create, update } = useRoleActions();
  const [role_id, setRoleId] = useState(0);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [selected_envs, setSelectedEnvs] = useState({});
  const { environments: stored_environments } = useSelector((state) => state);

  useEffect(() => {
    if (!role || !role.id) return;
    setRoleId(role?.id);
    setName(role?.name);
    setDescription(role?.description);

    const role_envs = role.environments.reduce((sac, env) => ({ ...sac, [env.code]: env }), {});
    const envs = Object.values(stored_environments).reduce((sac, { code }) => {
      return {
        ...sac,
        [code]: [
          {
            code,
            label: role_envs[code]?.label || 'Read',
            value: role_envs[code]?.value || 'read'
          }
        ]
      };
    }, {});
    setSelectedEnvs(envs);
  }, [role]);

  useEffect(() => {
    if (role_id) return;
    const envs = Object.values(stored_environments).reduce((sac, env) => {
      return {
        ...sac,
        [env.code]: [
          {
            code: env.code,
            label: 'Read',
            value: 'read'
          }
        ]
      };
    }, {});
    setSelectedEnvs(envs);
  }, [stored_environments]);

  const clearForm = () => {
    setRoleId(0);
    setName('');
    setDescription('');

    const envs = {};
    Object.keys(selected_envs).forEach((code) => {
      envs[code] = [
        {
          code,
          label: 'Read',
          value: 'read'
        }
      ];
    });
    setSelectedEnvs(envs);
  };

  const handleEnvironmentValue = (environment, value) => {
    setSelectedEnvs((selected_envs) => ({
      ...selected_envs,
      [environment.code]: [
        {
          ...value,
          code: environment.code
        }
      ]
    }));
  };

  const submit = async () => {
    if (!name) return toast.error('Role name is required.');
    const environments = Object.values(selected_envs).map((env) => env[0]);

    const data = { name, description, environments };
    const { error, payload } = role_id ? await update(role_id, data) : await create(data);

    if (error) return toast.error(error);
    dispatch(addOneRoleToStore({ id: role_id, ...data, ...payload }));
    toast.success(`Role ${role_id ? 'updated' : 'created'} succesfully`);
    clearForm();
  };

  return (
    <div className={styles.roleForm}>
      <header>Create Role</header>
      <section>
        <SimpleInput
          label="Name (required):"
          input_id="role-name"
          value={name}
          placeholder="e.g Admin"
          onInput={setName}
        />
        <SimpleInput
          label="Description:"
          input_id="role-desc"
          value={description}
          placeholder="e.g Administrators only"
          onInput={setDescription}
        />
        {Object.values(stored_environments).map((environment) => (
          <div key={environment.code}>
            <label>{environment.name}:</label>
            <MultiSelect
              options={ENV_VALUES}
              onChange={(value) => handleEnvironmentValue(environment, value)}
              value={selected_envs[environment.code]}
              label="Select environments"
              isMulti={false}
            />
          </div>
        ))}
        <Button className={styles.button} onClick={submit} icon_name="check_circle" text="Save" />
        <Button className={styles.button} onClick={clearForm} type="secondary" text="Clear form" />
      </section>
    </div>
  );
};
