import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setPageTitle } from 'store/actions/header';
import { Switch, Route, Redirect } from 'react-router-dom';

import Roles from './roles';
import Environments from './environments';
import SlidingNav from 'app/shared/nav/sliding/sliding';
import styles from './settings.module.css';

const SettingsModule = () => {
  const dispatch = useDispatch();

  const nav_items = [
    {
      title: 'Roles',
      path: '/settings/roles'
    },
    {
      title: 'Environments',
      path: '/settings/environments'
    }
  ];

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Settings', path: '.' }]));
  }, [dispatch]);

  return (
    <div className={styles.wrapper}>
      <header>
        <SlidingNav nav_items={nav_items} />
      </header>
      <section className={styles.content}>
        <Switch>
          <Route path="/settings/roles" component={Roles} />
          <Route path="/settings/environments" component={Environments} />
          <Redirect from="/**" to="/settings/roles" />
        </Switch>
      </section>
    </div>
  );
};

export default SettingsModule;
