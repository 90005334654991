import React, { useState } from 'react';
import { APIForm } from './api';
import { EnvList } from './list';
import { EnvForm } from './form';
import styles from './env.module.css';

const EnvSetting = () => {
  const [selected_environment, setEnvironment] = useState({});

  const handleEnvironmentSelection = (environment) => {
    setEnvironment(environment);
  };

  return (
    <div className={styles.envsPage}>
      <EnvList onEdit={handleEnvironmentSelection} />
      <div>
        <APIForm />
        <EnvForm
          selected_environment={selected_environment}
          onEnvChange={handleEnvironmentSelection}
        />
      </div>
    </div>
  );
};

export default EnvSetting;
