import { useSelector } from 'react-redux';
import * as Service from 'services/user';

export const useUserActions = () => {
  const { token, tenant_id } = useSelector((state) => state.user_data);

  const create = async (data) => {
    return await Service.create({
      data: { ...data, tenant_id },
      token
    });
  };

  const invite = async (data) => {
    return await Service.invite({ data: { ...data, tenant_id }, token });
  };

  const read = async (query_string = '') => {
    return await Service.read({
      query_string: `${query_string}&tenant_id=${tenant_id}`,
      token
    });
  };

  const search = async (keys, keywords, query_string = '') => {
    return await Service.search(keys, keywords, {
      token,
      query_string
    });
  };

  const update = async (id, data) => {
    return await Service.updateById(id, {
      data,
      token,
      tenant_id
    });
  };

  const deleteMany = async (data = {}) => {
    return await Service.deleteBulk({
      token,
      data: { ...data, tenant_id }
    });
  };

  return { create, invite, read, update, search, deleteMany };
};
