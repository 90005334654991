/** */
export const CREATE_USER = 'create user';
export const DELETE_USER = 'delete user';
export const READ_USERS = 'read users';
export const UPDATE_USER = 'update user';

/** */
export const ADD_ONE_USER_TO_STORE = 'add one user to store';
export const ADD_MANY_USERS_TO_STORE = 'add many users to store';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add user search results to store';
export const LOAD_USERS_TO_STORE = 'load users to store from API call';
export const REMOVE_MANY_USERS_FROM_STORE = 'remove many users from the store.';
export const REMOVE_ONE_USER_FROM_STORE = 'remove a user from the store.';

/** */
export const createUser = (payload) => ({
  type: CREATE_USER,
  payload
});

export const deleteUser = (payload) => ({
  type: DELETE_USER,
  payload
});

export const deleteManyUsers = (payload) => ({
  type: REMOVE_MANY_USERS_FROM_STORE,
  payload
});

export const readUsers = (payload) => ({
  type: READ_USERS,
  payload
});

export const updateUser = (payload) => ({
  type: UPDATE_USER,
  payload
});

/** */

export const addManyUsersToStore = (payload) => ({
  type: ADD_MANY_USERS_TO_STORE,
  payload
});

export const addOneUserToStore = (payload) => ({
  type: ADD_ONE_USER_TO_STORE,
  payload
});

export const addUserSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadUsersToStore = (payload) => ({
  type: LOAD_USERS_TO_STORE,
  payload
});

export const removeManyUsersFromStore = (payload) => ({
  type: REMOVE_MANY_USERS_FROM_STORE,
  payload
});

export const removeOneUserFromStore = (payload) => ({
  type: REMOVE_ONE_USER_FROM_STORE,
  payload
});
