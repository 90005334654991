import React, { useState } from 'react';

import { RolesList } from './list';
import { RolesForm } from './form';
import styles from './api.module.css';

const RoleSetting = () => {
  const [selected_role, setSelectedRole] = useState({});

  const handleRoleSelection = (role) => {
    setSelectedRole(role);
  };
  return (
    <div className={styles.rolesPage}>
      <RolesList onSelect={handleRoleSelection} />
      <RolesForm role={selected_role} onEdit={handleRoleSelection} />
    </div>
  );
};

export default RoleSetting;
