/** */
import { useDispatch, useSelector } from 'react-redux';
import React, { useState, useEffect } from 'react';
import { Switch, Route } from 'react-router-dom';

import SideNav from './side-nav/side-nav';
import { ModuleLayout } from './modules';
import { addMetadata } from 'store/actions/metadata';
import './member.css';

const NotFound = () => (
  <div className="not-found">
    <p> You do not have access to any tenant.</p>
  </div>
);

const MemberArea = () => {
  const dispatch = useDispatch();
  const { tenant_id } = useSelector((state) => state.user_data);
  const [open_menu_tray, setOpenMenuTray] = useState(false);

  const setMobileFlag = () => {
    if (window.screen.width < 768) {
      dispatch(addMetadata({ is_mobile_view: true }));
    } else dispatch(addMetadata({ is_mobile_view: false }));
  };

  useEffect(() => {
    setMobileFlag();
    window.addEventListener('resize', () => {
      setMobileFlag();
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className="member-area-wrapper">
      <section className="app-body">
        {tenant_id ? (
          <>
            <SideNav open_tray={open_menu_tray} onTrayClose={() => setOpenMenuTray(false)} />
            <Switch>
              <Route
                path="/"
                component={() => <ModuleLayout setOpenMenuTray={setOpenMenuTray} />}
              />
            </Switch>
          </>
        ) : (
          <NotFound />
        )}
      </section>
    </div>
  );
};

export default MemberArea;
