import { useHistory } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import WebDatatable from 'app/shared/datatable/web/datatable';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';

import { addFlagSearchResultsToStore, addManyFlagsToStore } from 'store/actions/flag';
import { setPageTitle } from 'store/actions/header';
import { Button } from 'app/shared/button';
import { useFlagActions } from 'hooks/flags';

const ListCampaigns = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { read, search } = useFlagActions();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const stored_flags = useSelector((state) => state.flags);

  const [campaigns, setCampaigns] = useState([]);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading, setLoading] = useState(false);

  const [total_flags, setTotalFlags] = useState(0);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Flags', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setCampaigns(Object.values(stored_flags));
  }, [stored_flags]);

  const config = {
    actions: {
      single: ['View', 'Delete']
    },
    allow_bulk_action: true,
    css: {},
    fields: [
      {
        title: 'Name',
        key: 'name',
        isTitle: true,
        formatter: (value) => (value.length > 25 ? `${value.substr(0, 25)}...` : value)
      },
      {
        title: 'Description',
        key: 'description',
        isTagline: true
      },
      {
        title: 'Environments',
        key: 'environments',
        formatter: (value) => value.length
      },
      {
        title: 'Creation date',
        key: 'created_on',
        formatter: (value) => new Date(value).toDateString(),
        isMetadata: true
      }
    ],
    is_search_mode,
    items: campaigns,
    search_key: 'name',
    search_text: ''
  };

  const handleDatatableAction = (payload) => {
    const { name, type, data } = payload;

    if (type === 'single') {
      switch (name) {
        case 'Edit':
          history.push(`/flags/${data.id}`);
          break;
        default:
          history.push(`/flags/${data.id}`);
      }
    }
  };

  const handleItemClick = (payload) => {
    const { id } = payload;
    history.push(`/flags/${id}`);
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoading(true);
      const {
        error,
        payload: { data, meta }
      } = await read(`page=${page}&population=${population}&sort_by=-created_on`);

      if (error) return;

      dispatch(addManyFlagsToStore(data));
      setTotalFlags(meta.size);
    } catch (e) {
      dispatch(addManyFlagsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoading(true);
      const {
        error,
        payload: { data, meta }
      } = await search(keys, keyword, `page=${page}&population=${population}&sort_by=-created_on`);

      if (error) return;

      setTotalFlags(meta.size);
      if (page === 0) return dispatch(addFlagSearchResultsToStore(data));
      dispatch(addManyFlagsToStore(data));
    } catch (e) {
      dispatch(addManyFlagsToStore([]));
    } finally {
      setLoading(false);
    }
  };

  const table_actions = (
    <>
      <Button icon_name="add" text="Flag" onClick={() => history.push('/flags/new')} />
    </>
  );

  return (
    <div className="content-area">
      {is_mobile_view ? (
        <MobileDatatable
          config={config}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <WebDatatable
          config={{
            ...config,
            total_count: total_flags
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          checkbox
          loading_data={loading}
          table_actions={table_actions}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      )}
    </div>
  );
};

export default ListCampaigns;
