import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';
import styles from '../env.module.css';
import { useDispatch } from 'react-redux';
import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { useEnvActions } from 'hooks/environments';
import { addOneEnvironmentToStore } from 'store/actions/environments';

export const EnvForm = ({ selected_environment = {}, onEnvChange = (f) => f }) => {
  const { create, update } = useEnvActions();
  const dispatch = useDispatch();
  const [id, setId] = useState(0);
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [description, setDescription] = useState('');

  useEffect(() => {
    if (!Object.values(selected_environment).length) return;
    console.log(selected_environment);
    setId(selected_environment?.id || 0);
    setName(selected_environment?.name || '');
    setCode(selected_environment?.code || '');
    setDescription(selected_environment?.description || '');
  }, [selected_environment]);

  const clearForm = () => {
    setId(0);
    setName('');
    setCode('');
    setDescription('');
    onEnvChange({});
  };

  const submit = async () => {
    if (!name) return toast.error('Environment name cannot be empty');

    const data = { name, code, description };
    const { error, payload } = !id ? await create(data) : await update(id, data);
    if (error) return toast.error(error);

    toast.success(`${id ? 'Updated' : 'Created'} successfully`);
    dispatch(addOneEnvironmentToStore({ ...data, ...payload }));
    clearForm();
  };
  return (
    <div className={styles.envForm}>
      <header>Create Environment</header>
      <section>
        <SimpleInput
          label="Name (required):"
          input_id="env-name"
          placeholder="e.g US Staging One"
          value={name}
          onInput={setName}
        />
        <SimpleInput
          label="Code:"
          input_id="env-code"
          placeholder="e.g us-stg-1"
          value={code}
          onInput={setCode}
        />
        <SimpleInput
          label="Description:"
          input_id="env-desc"
          placeholder="e.g A server based in the United States of America"
          value={description}
          onInput={setDescription}
        />
        <Button className={styles.button} onClick={submit} icon_name="check_circle" text="Save" />
      </section>
    </div>
  );
};
