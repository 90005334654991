import React from 'react';
import { Switch, Route } from 'react-router-dom';

import List from './list/list';

const UsersModule = () => {
  return (
    <Switch>
      <Route path="/users" component={List} />
    </Switch>
  );
};

export default UsersModule;
