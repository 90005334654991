/** */
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';

import { urls } from 'services/_url';
import * as GuestService from 'services/guest';
import { Button } from 'app/shared/button';
import { formIsEmpty } from 'utilities/form';
import { Alert } from 'app/shared/form-alert';
import { IconInput, SimpleInput } from 'app/shared/input';
import styles from '../guest.module.css';

const SignupForm = () => {
  const history = useHistory();
  const [alert, setAlert] = useState({});
  const [name, setName] = useState();
  const [first_name, setFirstname] = useState();
  const [last_name, setLastname] = useState();
  const [email_address, setEmail] = useState('');
  const [email_to_resend, setEmailToResend] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
  const [resending, setResending] = useState(false);
  const [show_password, setShowPassword] = useState(false);
  const [show_resend_button, setShowResendButton] = useState(false);

  const clearForm = () => {
    setName('');
    setFirstname();
    setLastname();
    setEmail('');
    setPassword('');
  };

  const submitForm = () => {
    const form_data = {
      name,
      first_name,
      last_name,
      email_address,
      password
    };
    if (formIsEmpty(form_data)) {
      setAlert({
        message: 'Please fill all fields.'
      });
      return;
    }

    if (form_data.password.length < 8) {
      setAlert({
        message: 'Passwords must contain 8 or more characters.'
      });
      return;
    }

    const data_to_submit = { ...form_data };

    setLoading(true);
    GuestService.signup({ data: data_to_submit })
      .then((response) => {
        const { error, payload } = response;
        if (error) {
          setAlert({
            message: error
          });
          return;
        }

        setEmailToResend(email_address);
        setShowResendButton(true);
        setAlert({
          message: payload,
          type: 'success'
        });
        clearForm();
      })
      .finally(() => setLoading(false));
  };

  const toggleShowPassword = () => {
    setShowPassword(!show_password);
  };

  const handleResend = () => {
    setResending(true);
    GuestService.resendActivationLink(email_to_resend)
      .then((response) => {
        const { error, payload } = response;
        if (error) {
          setAlert({
            message: error
          });
          return;
        }
        setAlert({
          message: payload,
          type: 'success'
        });
      })
      .finally(() => setResending(false));
  };

  return (
    <div>
      <Alert {...alert} />
      {show_resend_button && (
        <div className={styles.formText}>
          {!resending ? <b onClick={handleResend}>Resend Link</b> : <b>Resending</b>}
        </div>
      )}
      <SimpleInput value={name} onInput={(value) => setName(value)} placeholder="Company name" />
      <SimpleInput
        value={first_name}
        onInput={(value) => setFirstname(value)}
        placeholder="First name"
      />
      <SimpleInput
        value={last_name}
        onInput={(value) => setLastname(value)}
        placeholder="Last name"
      />
      <SimpleInput
        type="email"
        value={email_address}
        onInput={(value) => setEmail(value)}
        placeholder="Email address"
      />
      <IconInput
        icon_name={show_password ? 'hide' : 'show'}
        type={show_password ? 'text' : 'password'}
        placeholder="Password"
        value={password}
        onInput={(value) => setPassword(value)}
        onIconClick={toggleShowPassword}
        icon_position="right"
      />
      <div className={styles.formText}>
        By continuing, you agree to our{' '}
        <b>
          <a href={urls.terms}>terms</a>
        </b>{' '}
        and{' '}
        <b>
          <a href={urls.policy}>policies</a>
        </b>
        .
      </div>
      <Button
        className={styles.formBtn}
        onClick={() => submitForm()}
        text="Create Account"
        loading={loading}
      />

      <Button
        type="secondary"
        className={styles.formBtn}
        onClick={() => history.push('/login')}
        text="Log In"
      />
    </div>
  );
};

export default SignupForm;
