/** */
import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import FlagsModule from './flags/_index';
import UsersModule from './users/_index';
import SettingsModule from './settings/_index';
import MemberAreaHeader from './header/header';

export const ModuleLayout = ({ setOpenMenuTray }) => {
  return (
    <section className="app-content">
      <MemberAreaHeader onHamburgerClick={() => setOpenMenuTray(true)} />
      <Switch>
        <Route path="/flags" component={FlagsModule} />
        <Route path="/users" component={UsersModule} />
        <Route path="/settings" component={SettingsModule} />
        <Redirect from="/**" to="/flags" />
      </Switch>
    </section>
  );
};
