import { bindElementToQueries } from '@testing-library/react';

export const table_config = {
  actions: {
    bulk: ['Remove'],
    single: ['Edit', 'Re-Invite', 'Remove']
  },
  allow_bulk_action: true,
  css: {},
  fields: [
    {
      title: 'First name',
      key: 'first_name',
      isTitle: true
    },
    {
      title: 'Last name',
      key: 'last_name',
      isTitle: true
    },
    {
      title: 'Email',
      key: 'email_address',
      isTagline: true
    },
    {
      title: 'Status',
      key: 'status',
      isMetadata: bindElementToQueries
    },
    {
      title: 'Date added',
      key: 'created_on',
      formatter: (value) => {
        return value ? new Date(value).toDateString() : '';
      },
      isMetadata: true
    }
  ],
  items: [],
  // search_key: 'name',
  search_text: ''
};
