import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import React, { useEffect, useState } from 'react';
import styles from '../../flag.module.css';

const CONDITIONS = [
  {
    label: 'Matches some',
    value: 'some'
  },
  {
    label: 'Matches none',
    value: 'none'
  },
  {
    label: 'Greater than',
    value: 'greater'
  },
  {
    label: 'Less than',
    value: 'less'
  }
];

const EVALUATION = [
  {
    label: 'False',
    value: false
  },
  {
    label: 'True',
    value: true
  }
];

export const Criterion = ({ id, data, onChange = (f) => f }) => {
  const [property, setProperty] = useState('');
  const [values, setValues] = useState('');
  const [condition, setCondition] = useState(CONDITIONS[0]);
  const [evaluation, setEvaluation] = useState(EVALUATION[0]);

  useEffect(() => {
    if (!data || !Object.keys(data).length) return;
    setProperty(data.property);
    setValues(data.values);
    setCondition(CONDITIONS.find((condition) => condition.value === data.condition?.value));
    setEvaluation(EVALUATION.find((evaluation) => evaluation.value === data.evaluation?.value));
  }, [data]);

  useEffect(() => {
    onChange({
      key: id,
      property,
      values,
      evaluation,
      condition
    });
  }, [property, values, evaluation, condition]);

  return (
    <div className={styles.criterion}>
      <div className={styles.criterionInput}>
        <SimpleInput
          label="If property:"
          input_id="criterion_name"
          value={property}
          onInput={(e) => setProperty(e)}
        />
        <div>
          <label>{condition?.label || CONDITIONS[0].label}</label>
          <MultiSelect
            options={CONDITIONS}
            onChange={setCondition}
            value={condition}
            label="Select role"
            isMulti={false}
          />
        </div>
        <SimpleInput
          label=" of these values:"
          input_id="criterion_name"
          value={values}
          onInput={(e) => setValues(e)}
        />
        <div>
          <label>Evaluate to:</label>
          <MultiSelect
            options={EVALUATION}
            onChange={setEvaluation}
            value={evaluation}
            label="Select value"
            isMulti={false}
          />
        </div>
      </div>
    </div>
  );
};
