/** */
export const READ_MANY_ROLES = 'read multiple roles.';
export const READ_ONE_ROLE = 'read one roles.';

export const ADD_ONE_ROLE_TO_STORE = 'add one roles to store.';
export const ADD_SEARCH_RESULTS_TO_STORE = 'add roles search results to store.';
export const ADD_MANY_ROLES_TO_STORE = 'add multiple roles to atore.';
export const LOAD_ROLES = 'load up roles from API';
export const REM_ROLE = 'remove role';

/** */
export const readManyRoles = (payload) => ({
  type: READ_MANY_ROLES,
  payload
});

export const readOneRole = (payload) => ({
  type: READ_ONE_ROLE,
  payload
});

export const addManyRolesToStore = (payload) => ({
  type: ADD_MANY_ROLES_TO_STORE,
  payload
});

export const addOneRoleToStore = (payload) => ({
  type: ADD_ONE_ROLE_TO_STORE,
  payload
});

export const addRoleSearchResultsToStore = (payload) => ({
  type: ADD_SEARCH_RESULTS_TO_STORE,
  payload
});

export const loadRoleToStore = (payload) => ({
  type: LOAD_ROLES,
  payload
});

export const removeRoleFromStore = (payload) => ({
  type: LOAD_ROLES,
  payload
});
