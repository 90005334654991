/** */
import { urls } from './_url';
import { apiDelete, apiGet, apiPost, apiPut } from './_http';

/** */
const base_url = `${urls.server}/roles`;

export const create = async (options = {}) => {
  return await apiPost(`${base_url}`, { ...options });
};

export const deleteById = async (id, options) => {
  return await apiDelete(`${base_url}/${id}`, { ...options });
};

export const deleteContact = async (audience_id, options) => {
  return await apiDelete(`${base_url}/${audience_id}/contacts`, { ...options });
};

export const read = async (options = {}) => {
  return await apiGet(`${base_url}`, { ...options });
};

export const readById = async (id, options = {}) => {
  return await apiGet(`${base_url}/${id}`, { ...options });
};

export const search = async (keys, keyword, options = {}) => {
  const query_string = options.query_string
    ? `${options.query_string}&keyword=${keyword}`
    : `keyword=${keyword}`;
  return await apiGet(`${base_url}/search/${keys}`, {
    ...options,
    query_string
  });
};

export const clone = async (id, options = {}) => {
  return await apiPost(`${base_url}/clone/${id}`, { ...options });
};

export const updateById = async (id, options) => {
  return await apiPut(`${base_url}/${id}`, { ...options });
};
