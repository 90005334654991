import React, { useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import { Alert } from 'app/shared/form-alert';
import { formIsEmpty } from 'utilities/form';
import * as GuestService from 'services/guest';
import { Button } from 'app/shared/button';
import { IconInput } from 'app/shared/input';
import styles from '../guest.module.css';

const PasswordReset = () => {
  const { id } = useParams();
  const [alert, setAlert] = useState({});
  const [form_data, setFormData] = useState({});
  const [is_loading, setIsLoading] = useState(false);
  const [show_password, setShowPassword] = useState(false);
  const history = useHistory();

  const submitForm = () => {
    if (formIsEmpty(form_data)) {
      setAlert({ message: 'Please fill all fields.' });
      return;
    }

    const data = {
      recovery_id: id,
      password: form_data.password
    };

    setIsLoading(true);
    GuestService.resetPassword({ data })
      .then((response) => {
        const { error, payload } = response;

        if (error) {
          setAlert({ message: error });
          return;
        }

        setAlert({ message: payload, type: 'success' });
      })
      .finally(() => setIsLoading(false));
  };

  const toggleShowPassword = () => {
    setShowPassword(!show_password);
  };

  return (
    <div>
      <Alert {...alert} />
      <IconInput
        placeholder="Password"
        icon_name={show_password ? 'hide' : 'show'}
        type={show_password ? 'text' : 'password'}
        icon_position="right"
        onInput={(value) => setFormData({ ...form_data, password: value })}
        onIconClick={toggleShowPassword}
        value={form_data.password}
      />
      <Button
        className="w-100"
        onClick={() => submitForm()}
        text="Reset Password"
        loading={is_loading}
      />
      <div className={styles.formText} onClick={() => history.push('/login')}>
        Back to <b>Login</b>
      </div>
    </div>
  );
};

export default PasswordReset;
