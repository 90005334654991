/** */
import cx from 'classnames';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { remDataFromStore } from 'store/actions/user-data';

import ContextMenu from 'app/shared/datatable/context-menu/context-menu';
import Breadcrumbs from 'app/shared/breadcrumbs/breadcrumbs';

import { CreateButton } from 'app/shared/button/create';
import { Icon } from 'assets/icons';
import styles from './header.module.css';

const MemberAreaHeader = ({ onHamburgerClick }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { page_title } = useSelector((state) => state.header);
  const { first_name, last_name } = useSelector((state) => state.user_data);

  const icon_user_actions = [
    <span key={`${first_name} ${last_name}`}>{`${first_name} ${last_name}`} </span>,
    'Log Out'
  ];

  const icon_new_actions = ['Campaign', 'Audience', 'Contact', 'Transactional Email'];

  const handleLogout = () => {
    dispatch(remDataFromStore());
    window.localStorage.clear();
  };

  const handleUserAction = (data) => {
    if (typeof data !== 'string') return;

    switch (data.toLowerCase()) {
      case 'campaign':
        history.push('/editor/campaign');
        break;
      case 'contact':
        history.push('/contacts/new/single');
        break;
      case 'transactional email':
        history.push('/editor/transactional/');
        break;
      case 'log out':
        handleLogout();
        break;
      default:
    }
  };

  return (
    <header className={cx(styles.wrapper, 'shadow-sd')}>
      <div>
        <div>
          <span className={styles.menuIcon} onClick={onHamburgerClick}>
            <Icon name="menu" />
          </span>

          {page_title?.length && (
            <span className={styles.title}>
              <Breadcrumbs crumbs={page_title} />
            </span>
          )}
        </div>

        <div className={styles.actions}>
          <CreateButton actions={icon_new_actions} handleUserAction={handleUserAction} />
          <div className={styles.profileIcon}>
            <ContextMenu
              actions={icon_user_actions}
              callback={handleUserAction}
              text={<Icon name="profile" />}
            />
          </div>
        </div>
      </div>
    </header>
  );
};

export default MemberAreaHeader;
