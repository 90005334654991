import { Icon } from 'assets/icons';
import { useEnvActions } from 'hooks/environments';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addManyEnvironmentsToStore, removeEnvironmentFromStore } from 'store/actions/environments';
import styles from '../env.module.css';

const EnvItem = ({ data, onEdit = (f) => f }) => {
  const { deleteById: deleteOne } = useEnvActions();
  const dispatch = useDispatch();

  const handleDelete = async (id) => {
    if (!id) return toast.error('Deletion failed.');
    const { error } = deleteOne(id);
    if (error) return toast.error(error);

    dispatch(removeEnvironmentFromStore(id));
  };

  return (
    <div className={styles.envItem}>
      <div className={styles.itemHeader}>
        <h5>{data.name}</h5>
        <span className={styles.itemActions}>
          <span className={styles.edit} onClick={() => onEdit(data)}>
            <Icon name="edit" />
          </span>
          <span className={styles.delete} onClick={() => handleDelete(data.id)}>
            <Icon name="cancel" />
          </span>
        </span>
      </div>
      <p>{`${data.code} - ${data.description || 'N/A'}`}</p>
    </div>
  );
};

export const EnvList = ({ onEdit = (f) => f }) => {
  const { read } = useEnvActions();
  const dispatch = useDispatch();
  const { environments: stored_environments } = useSelector((state) => state);
  const [environments, setEnvironments] = useState([]);

  useEffect(() => {
    read().then(({ error, payload: { data } }) => {
      if (error) return toast.error(error);
      dispatch(addManyEnvironmentsToStore(data));
    });
  }, []);

  useEffect(() => {
    setEnvironments(Object.values(stored_environments));
  }, [stored_environments]);

  if (!environments || !environments.length) {
    return <div className={styles.noData}>No environments to display</div>;
  }

  return (
    <div className={styles.envList}>
      {(environments || []).map((environment) => (
        <EnvItem key={environment.id} data={environment} onEdit={onEdit} />
      ))}
    </div>
  );
};
