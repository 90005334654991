/** */
import { toast } from 'react-toastify';
import React, { useEffect, useState } from 'react';

import { isEmailValid } from 'app/shared/utils/input';

import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { MultiSelect } from 'app/shared/select';
import styles from './form.module.css';
import { useUserActions } from 'hooks/users';
import { useRoleActions } from 'hooks/roles';

const generatePassword = () => {
  let pass = '';
  const str = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ' + 'abcdefghijklmnopqrstuvwxyz0123456789@#$';

  for (let i = 1; i <= 14; i++) {
    const char = Math.floor(Math.random() * str.length + 1);

    pass += str.charAt(char);
  }

  return pass;
};
const ContactCreationForm = ({ data }) => {
  const { invite } = useUserActions();
  const { read } = useRoleActions();
  const [loading, setLoading] = useState(false);

  const [role, setRole] = useState([]);
  const [roles, setRoles] = useState([]);
  const [first_name, setFirst_name] = useState('');
  const [last_name, setLastname] = useState('');
  const [email_address, setEmailAddress] = useState('');

  useEffect(() => {
    read().then(({ payload: { data } }) => {
      setRoles(data);
    });
  }, []);

  /** */
  const clearForm = () => {
    setFirst_name('');
    setLastname('');
    setEmailAddress('');
    setRole([]);
  };

  const submitForm = async () => {
    if (!first_name || !email_address) {
      toast.error('Please fill compulsory fields.');
      return;
    }

    if (!isEmailValid(email_address)) {
      toast.error('Invalid email_address address.');
      return;
    }

    const form_data = {
      first_name,
      last_name,
      email_address,
      password: generatePassword(),
      role: role.value
    };

    setLoading(true);
    const { error, payload } = await invite({
      ...form_data
    });
    setLoading(false);
    if (error) {
      toast.error(error || 'Please fill compulsory fields.');
      return;
    }

    toast.success(payload);
    clearForm();
  };

  return (
    <div>
      <div className="grid cols-2 mt-2">
        <SimpleInput
          label="First name (required):"
          input_id="first_name"
          value={first_name}
          onInput={(e) => setFirst_name(e)}
        />
        <SimpleInput
          label="Last name (required):"
          input_id="last_name"
          value={last_name}
          onInput={(e) => setLastname(e)}
        />
      </div>
      <SimpleInput
        label="Email address (required):"
        value={email_address}
        onInput={(e) => setEmailAddress(e)}
      />
      <>
        <label>Role (required):</label>
        <MultiSelect
          options={roles.map((role) => ({ label: role.name, value: role.id }))}
          onChange={setRole}
          value={role}
          label="Select role"
          isMulti={false}
        />
      </>
      <div className="grid cols-2 mt-2">
        <div />
        <Button
          onClick={submitForm}
          icon_name="add"
          text="Invite"
          className={styles.inviteBtn}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ContactCreationForm;
