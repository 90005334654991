/** */
import {
  ADD_MANY_ENVIRONMENTS_TO_STORE,
  ADD_ONE_ENVIRONMENT_TO_STORE,
  ADD_SEARCH_RESULTS,
  LOAD_ENVIRONMENTS_TO_STORE,
  READ_MANY_ENVIRONMENTS,
  REM_ENVIRONMENT_FROM_STORE
} from '../actions/environments';

export default (state = {}, action) => {
  const { type, payload } = action;
  const current_state = { ...state };
  switch (type) {
    case ADD_MANY_ENVIRONMENTS_TO_STORE:
      return {
        ...state,
        ...payload.reduce(
          (environments, environment) => ({
            ...environments,
            [environment.id]: environment
          }),
          {}
        )
      };
    case ADD_ONE_ENVIRONMENT_TO_STORE:
      return {
        ...state,
        [payload.id]: payload
      };
    case ADD_SEARCH_RESULTS:
      return {
        ...payload.reduce(
          (environments, environment) => ({
            ...environments,
            [environment.id]: environment
          }),
          {}
        )
      };
    case LOAD_ENVIRONMENTS_TO_STORE:
      return {
        ...payload.reduce(
          (environments, environment) => ({
            ...environments,
            [environment.id]: environment
          }),
          {}
        )
      };
    case READ_MANY_ENVIRONMENTS:
      payload.forEach((id) => {
        delete current_state[id];
      });

      return {
        ...current_state
      };
    case REM_ENVIRONMENT_FROM_STORE:
      delete current_state[payload];
      return { ...current_state };
    default:
      return state;
  }
};
