/** */
import {
  ADD_ONE_USER_TO_STORE,
  ADD_MANY_USERS_TO_STORE,
  ADD_SEARCH_RESULTS_TO_STORE,
  REMOVE_MANY_USERS_FROM_STORE,
  REMOVE_ONE_USER_FROM_STORE,
  LOAD_USERS_TO_STORE
} from '../actions/users';

export default (state = {}, action) => {
  const { type, payload } = action;
  const current_state = state;
  switch (type) {
    case ADD_MANY_USERS_TO_STORE:
      return {
        ...state,
        ...payload.reduce(
          (users, user) => ({
            ...users,
            [user.id]: user
          }),
          {}
        )
      };
    case ADD_ONE_USER_TO_STORE:
      return {
        ...state,
        [payload.id]: payload
      };
    case ADD_SEARCH_RESULTS_TO_STORE:
      return {
        ...payload.reduce(
          (users, user) => ({
            ...users,
            [user.id]: user
          }),
          {}
        )
      };
    case LOAD_USERS_TO_STORE:
      return {
        ...payload.reduce(
          (users, user) => ({
            ...users,
            [user.id]: user
          }),
          {}
        )
      };
    case REMOVE_MANY_USERS_FROM_STORE:
      payload.forEach((id) => delete current_state[id]);
      return {
        ...current_state
      };
    case REMOVE_ONE_USER_FROM_STORE:
      delete current_state[payload];
      return { ...current_state };
    default:
      return state;
  }
};
