import { Button } from 'app/shared/button';
import { SimpleInput } from 'app/shared/input';
import { useEnvActions } from 'hooks/environments';
import { useFlagActions } from 'hooks/flags';
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import styles from '../flag.module.css';
import { Criterion } from './criterion';
import { processFlagConfig, formatFlagForDisplay } from './helper';

export const CreateFlag = ({ id }) => {
  const { read: fetchEnvironemnts } = useEnvActions();
  const { create, update, readById } = useFlagActions();
  const [name, setName] = useState('');
  const [flag_id, setFlagId] = useState(Number(id) ? id : 0);
  const [environments, setEnvironments] = useState([]);
  const [environment, setEnvironment] = useState({});
  const [description, setDescription] = useState('');
  const [saved_criteria, setSavedCriteria] = useState({});
  const [criteria, setCriteria] = useState({});
  const [criteria_comp, setCriteriaComp] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    // TODO: move to general location
    fetchEnvironemnts().then(({ error, payload }) => {
      if (error) return;
      const { data: saved_environments } = payload;
      const envs = saved_environments.reduce(
        (sac, { code, name }) => ({
          ...sac,
          [code]: { code, name, criteria: [] }
        }),
        {}
      );

      setEnvironment(() => saved_environments[0]);
      setEnvironments(envs);

      if (flag_id && Number(flag_id)) {
        readById(flag_id).then(({ payload }) => {
          if (!payload) return toast.error('Could not load flag');

          const { criteria, environments } = formatFlagForDisplay(payload, envs);
          updateState(payload, criteria, environments);

          const initial_environment = saved_environments[0].code;
          const env_criteria = environments[initial_environment].criteria.map((criterion) => ({
            ...criterion,
            condition: criteria[criterion.property].condition,
            evaluation: criteria[criterion.property].evaluation
          }));
          setCriteriaComp(() =>
            Object.values(env_criteria).map((c, i) => buildCriteriaComponent(i, c))
          );
        });
      }
    });
  }, []);

  const addCriteriaComponent = () => {
    const list = [...criteria_comp, buildCriteriaComponent(criteria_comp.length)];
    setCriteriaComp(list);
  };

  const buildCriteriaComponent = (id, data) => {
    return <Criterion key={id} id={id} data={data} onChange={handleCriterionChange} />;
  };

  const handleCriterionChange = (data) => {
    const { key, property, values, condition, evaluation } = data;
    setCriteria((criteria) => ({
      ...criteria,
      [key]: {
        property,
        values,
        condition,
        evaluation
      }
    }));
  };

  const handleEnvChange = (code) => {
    const env = environments[code];
    setEnvironment(env);
    const env_criteria = env.criteria.map((ev) => ({
      ...ev,
      condition: saved_criteria[ev.property].condition,
      evaluation: saved_criteria[ev.property].evaluation
    }));

    setCriteriaComp(
      env_criteria.map((criterion, id) => {
        return buildCriteriaComponent(id, {
          ...criterion
        });
      })
    );
  };

  const submit = async () => {
    if (!name) return toast.error('Flag name is required.');
    if (Object.values(criteria).find((c) => !c.property)) {
      return toast.error('Criterion property cannot be empty');
    }

    const prepared_for_db = processFlagConfig(criteria, environments, environment);
    const data = { ...prepared_for_db, name, description };

    setLoading(true);

    try {
      const {
        error,
        payload: { id: created_id }
      } = flag_id ? await update(flag_id, data) : await create(data);
      if (error) return toast.error(error);
      toast.success(`Flag ${flag_id ? 'updated' : 'created'} successfully.`);

      const { criteria: updated_criteria, environments: updated_envs } = formatFlagForDisplay(
        { ...data },
        environments
      );
      if (created_id) setFlagId(created_id);
      updateState(data, updated_criteria, updated_envs);
    } finally {
      setLoading(false);
    }
  };

  const updateState = (payload, db_criteria, environments) => {
    setName(payload.name);
    setSavedCriteria(db_criteria);
    setDescription(payload.description);
    setEnvironments(environments);
    setCriteria({});
  };

  return (
    <div className={styles.editorWrapper}>
      <div className={styles.titleBar}>
        <div className={styles.titleRow}>
          <SimpleInput
            label="Name (required):"
            input_id="flag_name"
            value={name}
            onInput={(e) => setName(e)}
          />
          <SimpleInput
            label="Description:"
            input_id="flag_desc"
            value={description}
            onInput={(e) => setDescription(e)}
            wrapperClassName={styles.description}
          />
        </div>
        <div className={styles.btnWrap}>
          <Button
            onClick={submit}
            icon_name="add"
            text="Save"
            className={styles.saveBtn}
            loading={loading}
          />
        </div>
      </div>
      <div className={styles.criteriaWrap}>
        <div className={styles.envToggle}>
          Environment: &nbsp; &nbsp;
          <select onChange={(e) => handleEnvChange(e.target.value)} value={environment?.code}>
            {Object.values(environments).map(({ code, name }, id) => (
              <option value={code} key={id}>
                {name}
              </option>
            ))}
          </select>
        </div>
        <>{criteria_comp}</>
        <div className={styles.btnWrap}>
          <Button
            onClick={addCriteriaComponent}
            icon_name="add"
            text="Add Criterion"
            className={styles.addCriterionBtn}
            type="secondary"
          />
        </div>
      </div>
    </div>
  );
};
