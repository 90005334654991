import { toast } from 'react-toastify';
import { table_config } from './helper';
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import GmModal from 'app/shared/modal/modal';
import MobileDatatable from 'app/shared/datatable/mobile/datatable';
import WebDataTable from 'app/shared/datatable/web/datatable';
import ConfirmationDialog from 'app/shared/dialogs/confirmation';
import UserImport from 'app/member/modules/users/import/import';
import UserCreation from 'app/member/modules/users/create';
import { useUserActions } from 'hooks/users';

import {
  addManyUsersToStore,
  addUserSearchResultsToStore,
  removeManyUsersFromStore
} from 'store/actions/users';
import { setPageTitle } from 'store/actions/header';
import { Button } from 'app/shared/button';
import styles from './list.module.css';

const UserList = () => {
  const { deleteMany, invite, read, search } = useUserActions();
  const { is_mobile_view } = useSelector((state) => state.metadata);
  const { tenant_id, name } = useSelector((state) => state.user_data);
  const stored_users = useSelector((state) => state.users);

  const dispatch = useDispatch();

  const [items, setItems] = useState([]);
  const [selected_users, setSelectedUsers] = useState([]);
  const [unselect_items] = useState(true);
  const [show_creation_modal, setShowCreationModal] = useState(false);
  const [show_upload_modal, setShowUploadModal] = useState(false);
  const [is_search_mode, setSearchMode] = useState(false);
  const [loading_data, setLoadingData] = useState(false);
  const [user_count, setUserCount] = useState(0);

  const [show_delete_confirmation, setShowDeleteConfirmation] = useState(false);
  const [users_to_delete, setUsersToDelete] = useState([]);

  useEffect(() => {
    dispatch(setPageTitle([{ title: 'Users', path: '.' }]));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const users = Object.values(stored_users).map((u) => ({
      ...u,
      tenant_id,
      name,
      status: u.tenants.find((t) => t.id === tenant_id)?.status || 'unkown'
    }));

    setItems(users);
  }, [stored_users]);

  const deleteUsers = async (user_ids = []) => {
    if (!user_ids || !Array.isArray(user_ids)) return;

    const options = {
      id: user_ids.toString()
    };
    const { error } = await deleteMany({ options });
    if (error) {
      toast.error('could not delete contact(s).');
      return;
    }

    dispatch(removeManyUsersFromStore(user_ids));
    toast.success('contact(s) deleted successfully.');
  };

  const handleConfirmation = (permitted) => {
    if (permitted) {
      deleteUsers(users_to_delete);
    }

    setUsersToDelete([]);
    setShowDeleteConfirmation(false);
  };

  const handleDatatableAction = (action) => {
    const { name, type, data } = action;
    if (type.toLowerCase() === 'bulk') {
      switch (name.toLowerCase()) {
        case 'remove':
          setUsersToDelete(data.map((item) => item.id));
          setShowDeleteConfirmation(true);
          break;
        default:
      }
    }

    if (type.toLowerCase() === 'single') {
      switch (name.toLowerCase()) {
        case 'edit':
          setSelectedUsers(() => [data]);
          setShowCreationModal(true);
          break;
        case 'remove':
          setUsersToDelete([data.id]);
          setShowDeleteConfirmation(true);
          break;
        case 're-invite':
          toast.info('Sending invite to ' + data.email_address);
          invite({ ...data }).then(({ error, payload }) => {
            if (error) toast.error(error);
            else toast.success(payload);
          });
          break;
        default:
      }
    }
  };

  const handleItemClick = (data) => {
    setSelectedUsers(() => [data]);
    setShowCreationModal(true);
  };

  const handleDataRequest = async (page, population = 50) => {
    try {
      setLoadingData(true);
      const response = await read(`sort_by=-created_on&page=${page}&population=${population}`);
      const {
        error,
        payload: { data, meta }
      } = response;
      if (error) return;

      setUserCount(meta.size);
      dispatch(addManyUsersToStore(data));
    } catch (e) {
      dispatch(addManyUsersToStore([]));
    } finally {
      setLoadingData(false);
    }
  };

  const handleSearchRequest = async (keys, keyword, page, population = 50) => {
    if (!keys) return;
    try {
      setLoadingData(true);
      const response = await search(keys, keyword, {
        query_string: `sort_by=-created_on&page=${page}&population=${population}`
      });

      const {
        error,
        payload: { data, meta }
      } = response;
      if (error) return;

      setUserCount(meta.size);
      if (page === 0) return dispatch(addUserSearchResultsToStore(data));
      dispatch(addUserSearchResultsToStore(data));
    } catch (e) {
      dispatch(addUserSearchResultsToStore([]));
    } finally {
      setLoadingData(false);
    }
  };

  const table_actions = (
    <>
      <Button icon_name="add" text="User" onClick={() => setShowCreationModal(true)} />
      <Button icon_name="upload" text="Upload Users" onClick={() => setShowUploadModal(true)} />
    </>
  );

  return (
    <div className="content-area">
      {is_mobile_view ? (
        <MobileDatatable
          config={{
            ...table_config,
            is_search_mode,
            items,
            total_count: user_count
          }}
          action={handleDatatableAction}
          onClick={handleItemClick}
          onListModeChange={setSearchMode}
          onDataRequest={handleDataRequest}
          onSearchRequest={handleSearchRequest}
        />
      ) : (
        <div>
          <WebDataTable
            config={{
              ...table_config,
              is_search_mode,
              items,
              total_count: user_count
            }}
            action={handleDatatableAction}
            onClick={handleItemClick}
            checkbox
            loading_data={loading_data}
            table_actions={table_actions}
            onDataRequest={handleDataRequest}
            onSearchRequest={handleSearchRequest}
            resetBulkSelection={unselect_items}
          />
        </div>
      )}
      <GmModal
        bodyClassName={styles.importsModal}
        title="Import Users"
        show_title={true}
        show_modal={show_upload_modal}
        onClose={() => setShowUploadModal(false)}
      >
        <UserImport onDataRequest={handleDataRequest} />
      </GmModal>
      <GmModal
        bodyClassName={styles.creationModal}
        title="Invite User"
        show_title={true}
        show_modal={show_creation_modal}
        onClose={() => setShowCreationModal(false)}
      >
        <UserCreation data={selected_users[0]} />
      </GmModal>

      <ConfirmationDialog
        title="Delete User"
        message="Are you sure you want to delete this user?"
        callback={handleConfirmation}
        is_open={show_delete_confirmation}
      />
    </div>
  );
};

export default UserList;
