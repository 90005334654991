import { Icon } from 'assets/icons';
import { useRoleActions } from 'hooks/roles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { addManyRolesToStore, removeRoleFromStore } from 'store/actions/role';
import styles from '../api.module.css';

const RoleItem = ({ role, onSelect = (f) => f }) => {
  const { deleteById: deleteOne } = useRoleActions();
  const dispatch = useDispatch();

  const handleDelete = async (id) => {
    if (!id) return toast.error('Deletion failed.');
    const { error } = await deleteOne(id);
    if (error) return toast.error(error);

    dispatch(removeRoleFromStore(id));
    toast.success('Role deleted successfully');
  };

  return (
    <div className={styles.envItem}>
      <div className={styles.itemHeader}>
        <h5>{role.name}</h5>
        <span className={styles.itemActions}>
          <span className={styles.edit} onClick={() => onSelect(role)}>
            <Icon name="edit" />
          </span>
          <span className={styles.delete} onClick={() => handleDelete(role.id)}>
            <Icon name="cancel" />
          </span>
        </span>
      </div>
      <p>{`${role.description || 'N/A'}`}</p>
    </div>
  );
};

export const RolesList = ({ onSelect = (f) => f }) => {
  const { read } = useRoleActions();
  const dispatch = useDispatch();
  const { roles: stored_roles } = useSelector((state) => state);
  const [roles, setRoles] = useState([]);

  useEffect(() => {
    read().then(({ error, payload: { data } }) => {
      if (error) return toast.error(error);

      dispatch(addManyRolesToStore(data));
    });
  }, []);

  useEffect(() => {
    setRoles(Object.values(stored_roles));
  }, [stored_roles]);

  if (!roles || !roles.length) {
    return <div className={styles.noData}>No roles to display</div>;
  }

  return (
    <div className={styles.roleList}>
      {roles.map((role) => (
        <RoleItem key={role.id} role={role} className={styles.roleItem} onSelect={onSelect} />
      ))}
    </div>
  );
};
