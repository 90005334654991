import React, { useEffect } from 'react';
import { useTenantActions } from 'hooks/tenant';
import styles from '../env.module.css';
import { useDispatch, useSelector } from 'react-redux';
import { IconInput } from 'app/shared/input';
import { Button } from 'app/shared/button';
import { toast } from 'react-toastify';
import { addDataToStore } from 'store/actions/user-data';

export const APIForm = () => {
  const { generateAPIKey, readById } = useTenantActions();
  const dispatch = useDispatch();
  const tenant_data = useSelector((state) => state.user_data);

  useEffect(() => {
    if (tenant_data.api_key) return;
    readById(tenant_data.id).then(({ payload }) => {
      if (!payload) return;

      dispatch(addDataToStore(payload));
    });
  }, []);

  const copyKey = () => {
    navigator.clipboard
      .writeText(tenant_data.api_key)
      .then(() => toast.info('Copied.'))
      .catch((e) => e);
  };

  const submit = async () => {
    const {
      error,
      payload: { data }
    } = await generateAPIKey(tenant_data.id);
    if (error) return toast.error(error);

    dispatch(addDataToStore({ ...tenant_data, ...data }));
  };

  return (
    <div className={styles.envForm}>
      <header>API Key</header>
      <section>
        <IconInput
          input_id="env-desc"
          icon_name="copy"
          wrapperClassName={styles.inputWrap}
          iconClassName={styles.copyIcon}
          icon_position="right"
          value={tenant_data.api_key}
          onIconClick={copyKey}
          disabled
        />
        <Button className={styles.button} onClick={submit} text="Generate API Key" />
      </section>
    </div>
  );
};
