/** */
import React from 'react';
import cx from 'classnames';
import { Route, Switch, useHistory } from 'react-router-dom';

/** */
import AccountActivation from './activation/activation';
import AcceptInvitation from './acceptance';
import LoginForm from './login/login';
import PasswordRecovery from './password/recovery';
import PasswordReset from './password/reset';
import SignupForm from './signup/signup';

/** */
import styles from './guest.module.css';

const GuestArea = () => {
  const history = useHistory();
  return (
    <div className={styles.wrapper}>
      <div className={cx(styles.header, 'flex-row-center')}>
        <span className={styles.siteName} onClick={() => history.push('/')}>
          {' '}
          Go-Flags{' '}
        </span>
      </div>
      <div className={styles.formWrapper}>
        <Switch>
          <Route path="/login" component={LoginForm} />
          <Route path="/activation/:id" component={AccountActivation} />
          <Route path="/acceptance/:tenant_id/:user_id" component={AcceptInvitation} />
          <Route path="/password/recovery/" component={PasswordRecovery} />
          <Route path="/password/reset/:id" component={PasswordReset} />
          <Route path="/" component={SignupForm} />
        </Switch>
      </div>
    </div>
  );
};

export default GuestArea;
