/** */
const { REACT_APP_ENV } = process.env;

const app_config = {
  development: {
    server: 'http://localhost:5100'
  },
  staging: {
    server: 'https://api.go-flags.dev'
  },
  production: {
    server: 'https://api.go-flags.com'
  }
};

export const urls = { ...app_config[REACT_APP_ENV || 'development'] };
